export enum BankActionTypes {
    API_RESPONSE_SUCCESS = '@@apps/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@apps/API_RESPONSE_ERROR',
    UPD_CheckReceipt = '@@apps/UPD_CheckReceipt',
    Add_CheckReceipt = '@@apps/Add_CheckReceipt',
    UPD_CheckPayment = '@@apps/UPD_CheckPayment',
    Add_CheckPayment = '@@apps/Add_CheckPayment',
    ADD_BANK = '@@apps/ADD_BANK',
    Add_ENCASH = '@@apps/Add_ENCASH',
    ADD_CHEQUETRANSFER = '@@apps/ADD_CHEQUETRANSFER',
    UPD_ENCASH = '@@apps/UPD_ENCASH',
    UPD_CHQUETRANFER = '@@apps/UPD_CHQUETRANFER',
    ADD_CHEQUETRANSFERRETURN = '@@apps/ADD_CHEQUETRANSFERRETURN',
    UPD_CHQUETRANFERRETURN = '@@apps/UPD_CHQUETRANFERRETURN',
    ADD_BANK_ACC = '@@apps/ADD_BANK_ACC',
    UPD_RFUND = '@@apps/UPD_RFUND',
    Add_REFUND = '@@apps/Add_REFUND',
    RESET = '@@apps/RESET',
}
// constants
import { SupplierActionTypes } from './constants';

export type SupplierActionType = {
    type:
        | SupplierActionTypes.API_RESPONSE_SUCCESS
        | SupplierActionTypes.API_RESPONSE_ERROR
        | SupplierActionTypes.RESET
        | SupplierActionTypes.ADD_SUPPLIER
        | SupplierActionTypes.UPD_SUPPLIER
        | SupplierActionTypes.ADD_MULTIPLE_SUPPLIER
        | SupplierActionTypes.UPD_MULTIPLE_SUPPLIER
        | SupplierActionTypes.OPEN_VEN_BALANCE
        | SupplierActionTypes.VEN_GROUP;
    payload: {} | string;
};

type SupplierData = {
    id: string;
    branchid: string;
    suppliername: string;
    urdu_suppliername: string;
    address1: string;
    city: string;
    country: string;
    firstname: string;
    middlename: string;
    lastname: string;
    phone1: string;
    phone1comment: string;
    phone2: string;
    cellphone: string;
    phone2comment: string;
    email1: string;
    email1comment: string;
    email2: string;
    email2comment: string;
    supplier_group_id: string;
    purchasecategoryid: string;
    paytermsid: string;
    notes: string;
};
type UnitData = {
    unitname: string;
};
type BrandData = {
    name: string;
};
type ColorData = {
    name: string;
};

// common success
export const supplierApiResponseSuccess = (actionType: string, data: SupplierData | {}): SupplierActionType => ({
    type: SupplierActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const supplierApiResponseError = (actionType: string, error: string): SupplierActionType => ({
    type: SupplierActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const addSupplier = (
    suppliername: string,
    urdu_suppliername: string,
    address1: string,
    city: string,
    country: string,
    firstname: string,
    middlename: string,
    lastname: string,
    phone1: string,
    phone1comment: string,
    phone2: string,
    cellphone: string,
    phone2comment: string,
    email1: string,
    email1comment: string,
    email2: string,
    email2comment: string,
    supplier_group_id: string,
    purchasecategoryid: string,
    paytermsid: string,
    notes: string,
    cnic: string,
    cellphonecode: string,
    phone1code: string
): SupplierActionType => ({
    type: SupplierActionTypes.ADD_SUPPLIER,
    payload: {
        suppliername,
        urdu_suppliername,
        address1,
        city,
        country,
        firstname,
        middlename,
        lastname,
        phone1,
        phone1comment,
        phone2,
        cellphone,
        phone2comment,
        email1,
        email1comment,
        email2,
        email2comment,
        supplier_group_id,
        purchasecategoryid,
        paytermsid,
        notes,
        cnic,
        cellphonecode,
        phone1code,
    },
});
export const updSupplier = (
    id: string,
    suppliername: string,
    urdu_suppliername: string,
    address1: string,
    city: string,
    country: string,
    firstname: string,
    middlename: string,
    lastname: string,
    phone1: string,
    phone1comment: string,
    phone2: string,
    cellphone: string,
    phone2comment: string,
    email1: string,
    email1comment: string,
    email2: string,
    email2comment: string,
    supplier_group_id: string,
    purchasecategoryid: string,
    paytermsid: string,
    notes: string,
    cnic: string,
    cellphonecode: string,
    phone1code: string
): SupplierActionType => ({
    type: SupplierActionTypes.UPD_SUPPLIER,
    payload: {
        id,
        suppliername,
        urdu_suppliername,
        address1,
        city,
        country,
        firstname,
        middlename,
        lastname,
        phone1,
        phone1comment,
        phone2,
        cellphone,
        phone2comment,
        email1,
        email1comment,
        email2,
        email2comment,
        supplier_group_id,
        purchasecategoryid,
        paytermsid,
        notes,
        cnic,
        cellphonecode,
        phone1code,
    },
});
export const venGroup = (group_name: string): SupplierActionType => ({
    type: SupplierActionTypes.VEN_GROUP,
    payload: { group_name },
});
export const addVenBalance = (entrydate: string, rowisedata: Object) => ({
    type: SupplierActionTypes.OPEN_VEN_BALANCE,
    payload: { entrydate, rowisedata },
});
export const addMultipleSupplier = (data_array) => ({
    type: SupplierActionTypes.ADD_MULTIPLE_SUPPLIER,
    payload: { data_array },
});
export const updMultipleSupplier = (data_array, id) => ({
    type: SupplierActionTypes.UPD_MULTIPLE_SUPPLIER,
    payload: { data_array, id },
});

export const resetSupplier = (): SupplierActionType => ({
    type: SupplierActionTypes.RESET,
    payload: {},
});
